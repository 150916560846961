<template>
    <div>
        <!-- 第一區 -->
        <div class="bg-sukudai-green py-4">
            <b-container class="bv-example-row">
                <b-row cols="12" class="p-2 justify-content-center">
                    <b-col cols="2" class="w-100"
                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                    <b-col cols="6">
                        <h2 class="text-white col-12 text-center font-weight-bolder">服務特色</h2>
                    </b-col>
                    <b-col cols="2" class="w-100"
                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                </b-row>
                <b-row class="flex-column-reverse flex-lg-row">
                    <!-- 第一區 左側 -->
                    <b-col cols="12" lg="6">
                        <div class="bg-white rounded p-3">
                            <div style="border-left:1px solid #000" class="d-flex flex-column pl-3">
                                <span
                                    class="px-2 py-1 mb-2 bg-sukudai-info font-weight-bold w-fit-content font-lg">
                                    安心宣告
                                </span>
                                <span>
                                    1.完全合法：速可貸為政府立案：完全合法的公司，為全台多間銀行的合作夥伴。<br/>
                                    2.專業服務：我們擁有許多金融與法務專業經驗，為每位客戶規劃量身方案，協助解決借貸問題。<br/>
                                    3.資料安全：合法立案規劃與申辦；過程中嚴格執行保密作業，絕不讓客戶個資有任何外洩的風險。<br/>
                                    4.杜絕高利貸：速可貸協助每位客戶達成借貸目標，絕對安全合法；若您有資金需求；我們也邀請您與我們一同杜絕高利貸。
                                </span>
                            </div>
                        </div>
                    </b-col>

                    <!-- 第一區 右側 -->
                    <b-col cols="12" lg="6">
                        <div class="">
                            <ImageCarousel carousel-name="Features"/>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>


        <!-- 第二區 -->
        <div class="pt-4 pb-2 text-center">
            <h2>
                <span class="px-4" style="background: linear-gradient(transparent 80%, var(--sukudai-title-info) 80%);">
                    四大特色
                </span>
            </h2>
            <b-container class="bv-example-row text-center py-2">
                <b-row class="justify-content-center">
                    <b-col cols="5" lg="2">
                        <b-button class="w-100 border-0 bg-sukudai-green font-lg font-weight-bolder py-1">
                            手續簡單
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_1.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="5" lg="2">
                        <b-button class="w-100 border-0 bg-sukudai-yellow font-lg font-weight-bolder py-1">
                            輕鬆還款
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_2.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="5" lg="2">
                        <b-button class="w-100 border-0 bg-sukudai-green font-lg font-weight-bolder py-1">
                            高額度
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_3.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="5" lg="2">
                        <b-button class="w-100 border-0 bg-sukudai-yellow font-lg font-weight-bolder py-1">
                            專業服務
                        </b-button>
                        <img src="../assets/icon/Advantage/icon_4.png" style="width: 100%"/>
                    </b-col>

                    <b-col cols="12" class="mt-2">
                        <b-button variant="warning" size="lg"
                                  class="text-white font-xl font-weight-bolder py-1 mx-3"
                                  :to="{ path: 'FormConsultation' }">
                            表單諮詢
                        </b-button>
                        <b-button variant="warning" size="lg" class="text-white font-xl font-weight-bolder py-1 mx-3"
                                  @click="$bvModal.show(modalId)">立即諮詢</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-modal :id="modalId" hide-footer>
            <quick-submit/>
        </b-modal>
    </div>
</template>

<script>
import ImageCarousel from "./ImageCarousel";
import quickSubmit from "@/components/quickSubmit";

export default {
    components: {
        ImageCarousel,
        quickSubmit
    },

    data() {
        return {
            modalId: 'contact-now-modal',
        }
    },
}
</script>